import File from "@/scripts/models/file.model";

export default class VolunteeringFile {

    constructor() {
        this.id = 0;
        this.volunteeringId = 0;
        this.isHero = false;
        this.fileId = 0;
        this.isFaviconFile = false;
        this.insertedAt = "";
        this.file = new File()
    }

}